






















import ThemeModel from '@/dtos/theme';
import Vue from 'vue';
import { mapGetters } from 'vuex';

// TODO: Add CTA to become presenter
export default Vue.extend({
  computed: {
    ...mapGetters({
      polls: 'viewer/getSessionPolls',
      userId: 'auth/getUserId',
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
  },
});
